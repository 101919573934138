@font-face {
  font-family: 'dealShaker';
  src: url('font/dealShaker.eot?5evhrw');
  src: url('font/dealShaker.eot?5evhrw#iefix') format('embedded-opentype'),
    url('font/dealShaker.ttf?5evhrw') format('truetype'), url('font/dealShaker.woff?5evhrw') format('woff'),
    url('font/dealShaker.svg?5evhrw#dealShaker') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'],
[class*='icon'] {
  font-family: 'dealShaker';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 992px) {
  [class^='icon-'],
  [class*=' icon-'],
  [class*='icon'] {
    position: relative;
  }
}

.icon {
  color: inherit;
  vertical-align: middle;
}

.icon-Add:before {
  content: '\e900';
  color: inherit;
  vertical-align: middle;
}
.icon-Arrow-Left:before {
  content: '\e901';
  color: inherit;
  vertical-align: middle;
}
.icon-Attachment:before {
  content: '\e902';
  color: inherit;
  vertical-align: middle;
}
.icon-Back:before {
  content: '\e903';
  color: inherit;
  vertical-align: middle;
}
.icon-Bell:before {
  content: '\e904';
  color: inherit;
  vertical-align: middle;
}
.icon-Bullet:before {
  content: '\e905';
  color: inherit;
  vertical-align: middle;
}
.icon-Calendar:before {
  content: '\e906';
  color: inherit;
  vertical-align: middle;
}
.icon-Cart-Active:before {
  content: '\e907';
  color: inherit;
  vertical-align: middle;
}
.icon-Cart:before {
  content: '\e908';
  color: inherit;
  vertical-align: middle;
}
.icon-Close:before {
  content: '\e909';
  color: inherit;
  vertical-align: middle;
}
.icon-Copy:before {
  content: '\e90a';
  color: inherit;
  vertical-align: middle;
}
.icon-Coupon-2:before {
  content: '\e90b';
  color: inherit;
  vertical-align: middle;
}
.icon-Coupons:before {
  content: '\e90c';
  color: inherit;
  vertical-align: middle;
}
.icon-Cover:before {
  content: '\e90d';
  color: inherit;
  vertical-align: middle;
}
.icon-Currency-Euro:before {
  content: '\e90e';
  color: inherit;
  vertical-align: middle;
}
.icon-Currency-One:before {
  content: '\e90f';
  color: inherit;
  vertical-align: middle;
}
.icon-Dashboard:before {
  content: '\e910';
  color: inherit;
  vertical-align: middle;
}
.icon-Dealsbag:before {
  content: '\e911';
  color: inherit;
  vertical-align: middle;
}
.icon-Delete:before {
  content: '\e912';
  color: inherit;
  vertical-align: middle;
}
.icon-Delivery-1:before {
  content: '\e913';
  color: inherit;
  vertical-align: middle;
}
.icon-Delivery-Type-Online-Service:before {
  content: '\e914';
  color: inherit;
  vertical-align: middle;
}
.icon-Delivery-Type-Redeem-at-location:before {
  content: '\e915';
  color: inherit;
  vertical-align: middle;
}
.icon-Delivery-Type-Self-organized:before {
  content: '\e916';
  color: inherit;
  vertical-align: middle;
}
.icon-Double-Chevron:before {
  content: '\e917';
  color: inherit;
  vertical-align: middle;
}
.icon-Download:before {
  content: '\e918';
  color: inherit;
  vertical-align: middle;
}
.icon-Email:before {
  content: '\e919';
  color: inherit;
  vertical-align: middle;
}
.icon-Ends-on:before {
  content: '\e91a';
  color: inherit;
  vertical-align: middle;
}
.icon-Favourite:before {
  content: '\e91b';
  color: inherit;
  vertical-align: middle;
}
.icon-Filter:before {
  content: '\e91c';
  color: inherit;
  vertical-align: middle;
}
.icon-Group-message:before {
  content: '\e91d';
  color: inherit;
  vertical-align: middle;
}
.icon-Hamburger:before {
  content: '\e91e';
  color: inherit;
  vertical-align: middle;
}
.icon-Inbox:before {
  content: '\e91f';
  color: inherit;
  vertical-align: middle;
}
.icon-Info-2:before {
  content: '\e920';
  color: inherit;
  vertical-align: middle;
}
.icon-Info:before {
  content: '\e921';
  color: inherit;
  vertical-align: middle;
}
.icon-Location:before {
  content: '\e922';
  color: inherit;
  vertical-align: middle;
}
.icon-Location-small:before {
  content: '\e923';
  color: inherit;
  vertical-align: middle;
}
.icon-Messages:before {
  content: '\e924';
  color: inherit;
  vertical-align: middle;
}
.icon-Moderator:before {
  content: '\e925';
  color: inherit;
  vertical-align: middle;
}
.icon-More:before {
  content: '\e926';
  color: inherit;
  vertical-align: middle;
}
.icon-Notifcations:before {
  content: '\e927';
  color: inherit;
  vertical-align: middle;
}
.icon-Percent:before {
  content: '\e928';
  color: inherit;
  vertical-align: middle;
}
.icon-Phone:before {
  content: '\e929';
  color: inherit;
  vertical-align: middle;
}
.icon-Play:before {
  content: '\e92a';
  color: inherit;
  vertical-align: middle;
}
.icon-Price:before {
  content: '\e92b';
  color: inherit;
  vertical-align: middle;
}
.icon-Profile:before {
  content: '\e92c';
  color: inherit;
  vertical-align: middle;
}
.icon-QuestionMark:before {
  content: '\e92d';
  color: inherit;
  vertical-align: middle;
}
.icon-QuestionMark-dotted:before {
  content: '\e92e';
  color: inherit;
  vertical-align: middle;
}
.icon-Search:before {
  content: '\e92f';
  color: inherit;
  vertical-align: middle;
}
.icon-SingleChevron:before {
  content: '\e930';
  color: inherit;
  vertical-align: middle;
}
.icon-Star:before {
  content: '\e931';
  color: inherit;
  vertical-align: middle;
}
.icon-Switch:before {
  content: '\e932';
  color: inherit;
  vertical-align: middle;
}
.icon-Tick:before {
  content: '\e933';
  color: inherit;
  vertical-align: middle;
}
.icon-View:before {
  content: '\e934';
  color: inherit;
  vertical-align: middle;
}
.icon-Wallet:before {
  content: '\e935';
  color: inherit;
  vertical-align: middle;
}
.icon-facebook:before {
  content: '\ea90';
  color: inherit;
  vertical-align: middle;
}

/* Modified */

.icon-SingleChevronLeft {
  display: inline-block;
  transform: rotateZ(180deg);
}
.icon-SingleChevronLeft:before {
  content: '\e92a';
  color: inherit;
  vertical-align: middle;
}
